import { signalStore, withComputed, withState } from "@ngrx/signals";
import { withEntities } from "@ngrx/signals/entities";
import { withScriptsApi } from "./scripts-api.feature";
import { ScenarioResponse } from "../../api/models/scenario-response";
import { computed } from "@angular/core";
import { initialScenarioState } from "./script.types";

export const ScriptsStore = signalStore(
    { providedIn: "root" },
    withState(initialScenarioState),
    withEntities<ScenarioResponse>(),
    withScriptsApi(),
    withComputed((store) => ({
        getSelectedScenario: computed(() => {
            return store.entityMap()[store.selected_id()];
        }),
    })),
)
